import React from "react";
// import {
//   FaGuitar,
//   FaSwift,
//   FaRocket,
//   FaClock,
//   FaClinicMedical,
//   FaSkiing,
//   FaChess,
//   FaApple,
//   FaHome,
//   FaPizzaSlice,
//   FaBriefcase,
//   FaFootballBall,
// } from 'react-icons/fa';
// // import { Link } from 'gatsby';
// import { IconContext } from 'react-icons';
// import careers from '../../static/careers/careers-cropped-2.jpg';
// import internship from '../../static/careers/internship.jpg';
import "./news/Article.scss";

import Layout from "../components/Layout/Layout";

const AboutUs = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">About the Company</h1>

            <p>
              S&T Mold Company was founded in 1995 as a subsidiary of S&T System
              Integration & Technology Distribution AG, Viena, Austria. The
              quality management system "S&T Mold" has been certified according
              to the international standard ISO 9000: 2002 (including: ISO
              9001:2015, ISO 14001:2015, ISO 27001:2018)
            </p>
            <p>
              S&T AG heads the hightech group with 6000 employees staff offices
              and operations located in more than 30 countries. The range of
              services provided includes data center operation, workplace
              manage-ment, managed printing, procurement, integration and
              maintenance of IT products, SAP operation and implementation as
              well as software development. S&T is listed on the TecDAX and SDAX
              of the German Stock Exchange and is among the leading providers of
              embedded industrial computers and Industry 4.0 technology.
            </p>
            <p>
              Today S&T Mold is a leading provider of IT solutions and services
              on the Moldovan IT market. S&T Mold has 85 employees’ staff with
              technical, project and software development departments. The
              emphasis is on developing areas of consulting, software solutions,
              professional services, managed services and IT.
            </p>
            <p>
              S&T Mold is aware that the only way to progress is quality.
              Therefore, special attention is given to quality, meeting and
              exceeding customer requirements, offering superior quality
              products acquired from leading IT manufacturers and professional
              service
            </p>
            <p className="bold">
              System Integration, Installation and Infrastructure Support,
              Consulting
            </p>
            <p>
              The Company is the System Integrator providing of IT solutions and
              services on the Moldovan and CIS IT market
            </p>
            <p className="bold">The main business activities are:</p>
            <p>
              Providing customers with comprehensive high-end solutions,
              hardware and software solutions of our strategic partners -
              leading corporations, such as Hewlett-Packard Enterprise,
              Hewlett-Packard Inc., Cisco Systems, Microsoft, Oracle, VMware,
              EMC, VEEAM, etc.
            </p>
            <p>
              System integration services, including consulting, development and
              project management, solutions implementation and commissioning,
              user training, warranty, and post-warranty service.
            </p>
            <p>
              With a high degree of development experience and outstanding
              references in the mapping of complex business processes, S&T is
              also a partner in the field of software development, providing
              strong implementation support and agile solutions.
            </p>
            <h1 className="title">Software segment</h1>
            <p className="bold">
              S&T's competence in the customer software segment include
              development services in the following platforms and areas:
            </p>
            <p className="bullet">Enterprise application development</p>
            <p className="bullet">Web development</p>
            <p className="bullet">
              Languages and technologies used: Java, .NET, Node.JS.
            </p>
            <p className="bullet">
              Containerized cloud architectures: Docker, Kubernetes
            </p>
            <p className="bullet">Front-end development</p>
            <h1 className="title">Certifications</h1>
            <p>
              S&T Mold offers high-end IT solutions in strategic cooperation
              with leading technology partners:
            </p>

            <p className="bullet">
              HEWLETT-PACKARD ENTERPRISE, partner since 1995, actual Platinum
              Partner 2021
            </p>
            <p>HPE Partner Ready Services Delivery Certificate</p>
            <p>Aruba Silver Partner 2021</p>
            <p className="bullet">
              HEWLETT-PACKARD INCORPORATED, actual Gold Partner 2021
            </p>
            <p>HP Inc. Partner First Gold Status 2021</p>
            <p>HP Inc. Partner First Service Delivery Certificate</p>
            <p className="bullet">
              ORACLE, partner since 1997, actual Platinum Partner
            </p>
            <p className="bullet">
              MICROSOFT, partner since 2008, actual Gold Partner
            </p>
            <p className="bullet">
              {" "}
              CISCO, partner since 1999, actual Gold Partner
            </p>
            <p>MicroFocus, Gold Partner</p>
            <p>Vmware Solution Provider Professional</p>
            <p>Huawei Partner</p>
            <p>
              IBM, Red Hat, Kaspersky, Motorola, Eaton, Check Point, Citrix,
              TrendMicro, Palo Alto Networks, Nutanix, Nexans, Nakivo, SoClass
            </p>
            <p className="bold">
              Certificates offer the following primary advantages:
            </p>
            <p>Access to a broad spectrum of HP equipment</p>
            <p>Special discounts</p>
            <p>Delivery preferential terms</p>
            <h1 className="title">IT Infrastructure Support</h1>
            <p>
              S&T Mold Company, since 1995, has an authorized Hewlett-Packard
              maintenance with certified specialists offering a high level of
              equipment maintenance. Thanks to our experience in servicing
              computer systems, networks, storage devices and peripherals, we
              are the vendor of choice for hardware support.
            </p>
            <p>
              S&T delivers comprehensive support to enterprises of all sizes and
              environments of all types, including desktops, client/server,
              print and mobile professionals across the country. HW services
              cover a full range of proven preventive, diagnostic, remedial, and
              repair services.
            </p>
            <p className="bold">
              Standard terms maintenance warranty and post warranty in Moldova
              by S&T Mold:
            </p>
            <p>Reaction time - it is the next working day.</p>
            <p>
              Time to replace damaged equipment - depending on the availability
              of parts required for repair and order performance period
              component by Hewlett Packard.
            </p>
            <p>
              Post warranty service conditions are determined in the contract
              for technical maintenance or service package HP Post Warranty Care
              Pack for appropriate equipment.
            </p>
            <p>
              Authorized technical service center: 8 Calea Iesilor Str, office
              6.
            </p>
            <p>Local storage of spares: 8 Calea Iesilor Str, office 5.</p>
            <h1 className="title">Competitive advantage of S&T Mold:</h1>
            <p>
              Qualified personnel and good knowledge of suppliers and products
              more effective combination of skills.
            </p>
            <p>
              Vast portfolio of branded products, some exclusively distributed
              by S&T.
            </p>
            <p>Good financial possibilities.</p>
            <p>Experience in managing complex projects.</p>
            <p>
              Proven ability to minimize technical risks, operational and
              financial complex projects.
            </p>
            <p>Strong local presence.</p>
            <h1 className="title">Customers</h1>
            <p>
              S&T provides to customers customized solutions based on technology
              and products of world hardware and software leaders. The S&T's
              setup combines the benefits of having local expertise and access
              to the experience and knowledge of staff in 30 countries.
            </p>
            <p>
              The many years' experience and the balanced product portfolio
              allows S&T Mold to offer optimal solutions to financial and
              communications companies, governmental agencies, industrial
              companies, retail agencies.
            </p>
            <p>
              S&T Mold Customers benefit from expanded support in the areas of
              IT strategy, processes, and IT sys-tems. Support services include
              consulting, development and implementation, and the implementation
              and operation of IT solutions custom tailored to customer
              requirements and processes. S&T solutions and services add value
              and provide customers quick ROI.
            </p>
            <p>
              The main element underlying S&T Mold SRL ability to offer the most
              appropriate solutions and services quality management system is
              implemented in the company.
            </p>
          </div>
        </div>
        {/* <div className="about__image-container">
        <img src={careers} alt="" />
        <div className="info">
          <h1>
            Vino in echipa S<span className="ampersand">&</span>T!
          </h1>
        </div>
      </div> */}
        {/* 
      <div className="about__ribbon">
        <div className="grid-container">
          <h1 className="title">Compact team, Global mission</h1>
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi
          </p>
        </div>
      </div>

      <div className="about__benefits">
        <h1 className="benefits-title">What we offer</h1>
        <div className="grid-container">
          <IconContext.Provider value={{ className: 'benefits-icons' }}>
            <div className="about__benefits-container">
              <div className="benefit">
                <FaRocket />
                <span>Fantastic work environment</span> */}
        {/* <span>Mediu de lucru fantastic </span> */}
        {/* </div>
              <div className="benefit">
                <FaClock />
                <span>Flexible schedule</span> */}
        {/* <span>Orar flexibil </span> */}
        {/* </div>
              <div className="benefit">
                <FaClinicMedical />
                <span>Social and medical benefits</span> */}
        {/* <span>Beneficii medicale si sociale </span> */}
        {/* </div>
              <div className="benefit">
                <FaGuitar />
                <span>Balance between work and your own time </span> */}
        {/* <span>Echilibru lucru - viata personala </span> */}
        {/* </div>
              <div className="benefit">
                <FaSwift />
                <span>New technologies and program languages </span> */}
        {/* <span>Tehnologii si limbaje noi </span> */}
        {/* </div>
              <div className="benefit">
                <FaChess />
                <span>Internships</span> */}
        {/* <span>Mentorat si cursuri</span> */}
        {/* </div>
              <div className="benefit">
                <FaBriefcase />
                <span>Western business culture</span> */}
        {/* <span>Cultura de business vestica </span> */}
        {/* </div>
              <div className="benefit">
                <FaApple />
                <span>Top Gear</span> */}
        {/* <s>Echipament de top </s pan> */}
        {/* </div>
              <div className="benefit">
                <FaSkiing />
                <span>Events and adventures </span> */}
        {/* <span>Evenimente si aventuri </span> */}
        {/* </div>
              <div className="benefit">
                <FaHome />
                <span>Remote work </span> */}
        {/* <span>Lucru de la distanta </span> */}
        {/* </div>
              <div className="benefit">
                <FaPizzaSlice />
                <span>Frucits and snacks </span> */}
        {/* <span>Fructe si snack-uri </span> */}
        {/* </div>
              <div className="benefit">
                <FaFootballBall />
                <span>Gym membership</span> */}
        {/* <span>Abonamente Sport</span> */}
        {/* </div>
            </div>
          </IconContext.Provider>
        </div>

        <div className="about__ribbon">
          <div className="grid-container">
            <h1>Echipă mică, misiune globală</h1>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi
            </p>
          </div>
        </div>
      </div> */}
      </div>
    </Layout>
  );
};

export default AboutUs;
